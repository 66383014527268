.logo {
  width: 70px;
  height: 70px;
  object-fit: contain;
}

.clearTrash {
  font-size: 12pt;
}
.MuiDropzoneArea-root {
  min-height: auto !important;
  padding: 15px 0;

  .MuiDropzoneArea-text {
    margin-top: 0;
  }

  .MuiGrid-container {
    width: 100%;
    margin: 0;

    .MuiDropzonePreviewList-imageContainer {
      width: 100%;
      padding-bottom: 15px;
      max-width: 100%;
      flex-basis: 100%;

      .MuiDropzonePreviewList-image {
        width: 100%;
        height: 22.5rem;
        object-fit: cover;
      }

      .MuiDropzonePreviewList-removeButton {
        top: 10px;
        right: 15px;
      }
    }
  }
}

.info-section {
  .custom-shape {
    &.square {
      float: left;
      width: 10px;
      height: 10px;
      margin: 5px;
      border: 1px solid rgba(0, 0, 0, 0.2);

      &.blue {
        background: #13b4ff;
      }

      &.green {
        background: #00fa3f;
      }
    }
  }
}

.price {
  &.blue {
    color: #13b4ff;
  }
  &.green {
    color: #00fa3f;
  }
}

.hidden {
  display: none;
}

table {
  .cell-desc {
    height: 100px;
    overflow: scroll;
    
    .se-component.se-image-container.__se__float-none {
      height: 80px;
      overflow: scroll;
    }
  }
}

.sidebar {
  .is-active {
    font-size: 0.9rem;
    font-weight: 800;
    color: white !important;
    border-bottom: 1px solid #155e35;
    -moz-box-shadow: inset 0 0 5px #1d834a;
    -webkit-box-shadow: inset 0 0 5px #1d834a;
    box-shadow: inset 0 0 25px #1d834a;
  }

  .sub-link-adminstrator-active {
    background-color: #155e35;
  }

  .nav-item {
    .nav-link {
      .fa-chevron-right {
        display: none;
      }

      .fa-chevron-down {
        display: block;
      }

      &.collapsed {
        .fa-chevron-right {
          display: block;
        }

        .fa-chevron-down {
          display: none;
        }
      }
    }
  }
}

.logo {
    width: 70px;
    height: 70px;
    object-fit: contain;
}

.clearTrash {
    font-size: 12pt;
}

.form_date {
    .MuiInputBase-root {
        border: 1px solid #b4b4b4;
        padding: 2px 2px 2px 10px;
        border-radius: 4px;
        &:hover {
           border-color: #000; 
        }
    }
    .MuiInput-underline::before {
        display: none;
    }

    .MuiFormHelperText-root {
        position: absolute;
        bottom: -20px;
    }
}

.MuiDropzoneArea-root {
    min-height: auto !important;
    padding: 15px 0;

    .MuiDropzoneArea-text {
        margin-top: 0;
    }

    .MuiGrid-container {
        width: 100%;
        margin: 0;

        .MuiDropzonePreviewList-imageContainer {
            width: 100%;
            padding-bottom: 15px;
            max-width: 100%;
            //flex-basis: 100%;
            flex-basis: 12em !important;

            .MuiDropzonePreviewList-image {
                width: 100%;
                height: 22.5rem;
                height: 100% !important;
                object-fit: cover;
            }

            .MuiDropzonePreviewList-removeButton {
                top: 10px;
                right: 15px;
            }
        }
    }
}
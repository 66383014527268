.sub-text{
    font-size: 13px;
    color: black;
}
.home-container{
    h6{
        margin: 0px;
    }
    .content-select-left{
        // height: 50px;
        align-items: center;
        
    }
    .content-select-left{
        .MuiFormControl-marginNormal{
            margin: 0px;
        }
    }
    .card-boby{
        // min-height: 600px;
    }
}
.highcharts-data-table table {
    min-width: 200px;
    max-width: 200px;
    margin: 0em auto;
}
.highcharts-credits{
    display: none !important;
}